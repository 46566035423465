import React from 'react';
import '../css/_gallery.scss';

import wnsEntrance from '../assets/gallery/wns-entrance.jpg';
import niceCup from '../assets/gallery/nice-cup.jpg';
import niceBed from '../assets/gallery/nice-bed.png';
import masterRoom from '../assets/gallery/lantern.png';
import stairs from '../assets/gallery/stairs.png';
import mirror from '../assets/gallery/mirror-bed.jpeg';

const Gallery = () => {
  const images = [
    [
      {
        src: niceBed,
        alt: 'Boat on Calm Water',
      },
      {
        src: wnsEntrance,
        alt: 'wns entrance',
      },
    ],
    [
      {
        src: niceCup,
        alt: 'Mountains in the Clouds',
      },
      {
        src: mirror,
        alt: 'Boat on Calm Water',
      },
    ],
    [
      {
        src: masterRoom,
        alt: 'Waves at Sea',
      },
      {
        src: stairs,
        alt: 'Yosemite National Park',
      },
    ],
  ];

  return (
    <div>
      <div className="row margin-gall ">
        <div className="main-text">
          <h1 className="fw-bold">Our Gallery</h1>
          <p>
            Our stunning guest rooms and suites, with their modern furnishings
            and stylish interiors, offer a serene retreat for relaxation and
            rejuvenation.
          </p>
        </div>
        {images.map((column, index) => (
          <div
            key={index}
            className="  col-lg-4 col-md-12 mb-4 mb-lg-0 img-fluid Wild Landscape"
          >
            {column.map((image, imgIndex) => (
              <img
                key={imgIndex}
                src={image.src}
                className="w-100 shadow-1-strong rounded mb-4 pers-height"
                alt={image.alt}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
