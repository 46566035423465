import React from 'react';
import '../css/_home.scss';
import Rooms from '../components/Rooms';
import Service from '../components/Service';
import Gallery from '../components/Gallery';

const handleButtonClick = () => {
  const iframeUrl = 'https://direct-book.com/properties/wnshoteldirect/';
  window.open(iframeUrl, '_blank');
};

function Home(props) {
  return (
    <>
      <div className="position-relative">
        <div className="hearth-img">
          <img alt="hearth" src={require('../assets/hearth.jpeg')} />
        </div>

        <div className="position-absolute booking-engine-container">
          <div className="booking-engine-iframe">
            <iframe
              title={'booking engine from little hotelier'}
              src="https://direct-book.com/properties/wnshoteldirect/"
              height="500"
              width="350"
              loading={'lazy'}
              frameBorder="1"
              scrolling="yes"
            />
          </div>
          <button className="booking-engine-button" onClick={handleButtonClick}>
            Book Now
          </button>
        </div>
      </div>
      <Rooms />
      <Service />
      <Gallery />
    </>
  );
}

export default Home;
