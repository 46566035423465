import React, { useEffect, useState } from 'react';

function LocationImages({ location }) {
  // The LocationImages component expects a location prop

  // Initialize the images state and the setImages function to update it
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Define an async function to load the images
    const loadImages = async () => {
      // Import the images and store them in the loadedImages array
      const loadedImages = await Promise.all(
        location.images.map((imageName) =>
          import(`../../assets/locations/${imageName}`)
        )
      );
      // Update the images state with the default exports of the loadedImages
      setImages(loadedImages.map((image) => image.default));
    };

    // Call the loadImages function
    loadImages();
  }, [location.images]); // Run the effect when the location.images prop changes

  return (
    // Render a div with the "row mt-4" CSS classes
    <div className="row mt-4">
      {/* Render a div with the "col-md-6" CSS classes */}
      <div className="col-md-6">
        {/* Render the location details in <p> elements with the "lead text-left" CSS classes */}
        <p className="lead text-left">{location.details}</p>
        <p className="lead text-left">{location.details1}</p>
        <p className="lead text-left">{location.details2}</p>
      </div>
      {/* Render a div with the "col-md-6" CSS classes */}
      <div className="col-md-6">
        {/* Add the 'justify-content-center' and 'align-items-center' classes to the div */}
        <div className="row justify-content-center align-items-center">
          {images.map((src, index) => (
            <div key={index} className="col-6 mb-3">
              <img
                src={src}
                alt={`${location.name} ${index + 1}`}
                className="img-fluid rounded"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

// Export the LocationImages component as a memoized component
export default React.memo(LocationImages);
