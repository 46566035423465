import React from 'react';
import '../css/_rooms.scss';
import KingBedIcon from '@mui/icons-material/KingBed';

function Rooms() {
  return (
    <>
      <div className="main-container">
        <div className="main-text">
          <h1 className="fw-bold">Our Rooms</h1>
          <p>Find suitable rooms just one search away</p>
        </div>
      </div>
      <div className="wrap ">
        <div className="box">
          <div className="box-top">
            <img
              className="box-image"
              src={require('../assets/rooms/twin-room.jpeg')}
            />
            <div className="title-flex">
              <h3 className="box-title">Family Room</h3>
              <p className="user-follow-info">
                Two large double beds
                <KingBedIcon className="king-bed"></KingBedIcon>
                <KingBedIcon className="king-bed"></KingBedIcon>
              </p>
            </div>
            <p className="description">
              This family room features a tea/coffee maker, electric kettle and
              private entrance.Comfy beds and a Room size of 139 ft².
            </p>
          </div>
          <a
            href="https://direct-book.com/properties/wnshoteldirect/"
            className="button custom-a"
            target="_blank"
          >
            Book Now
          </a>
        </div>
        <div className="box">
          <div className="box-top">
            <img
              className="box-image"
              src={require('../assets/rooms/double-room.jpeg')}
              alt="Girl Eating Pizza"
            />
            <div className="title-flex">
              <h3 className="box-title">Double Room</h3>
              <p className="user-follow-info">
                {' '}
                One large double bed
                <KingBedIcon className="king-bed"></KingBedIcon>
              </p>
            </div>
            <p className="description">
              This double room has a flat-screen TV and electric kettle. A Room
              size of 85 ft².
            </p>
          </div>
          <a
            href="https://direct-book.com/properties/wnshoteldirect/"
            className="button custom-a"
            target="_blank"
          >
            Book Now
          </a>
        </div>
        <div className="box">
          <div className="box-top">
            <img
              className="box-image"
              src={require('../assets/rooms/superior-king.jpeg')}
              alt="Girl Eating Pizza"
            />
            <div className="title-flex">
              <h3 className="box-title">Superior King Room</h3>
              <p className="user-follow-info">
                One large double bed
                <KingBedIcon className="king-bed"></KingBedIcon>
              </p>
            </div>

            <p className="description">
              This double room has a private entrance, electric kettle and hot
              tub. With a room size of 267 ft².
            </p>
          </div>
          <a
            href="https://direct-book.com/properties/wnshoteldirect/"
            className="button custom-a"
            target="_blank"
          >
            Book Now
          </a>
        </div>
      </div>
    </>
  );
}

export default Rooms;
