import React from 'react';
import '../css/_service.scss';
import { services } from './dynamic-data/services'; // Importing the services data from a separate file

const Service = () => {
  return (
    <div>
      <div className="first-text" id="services">
        <h1 class="fw-bold">Our Services</h1>
      </div>
      <div className="container">
        <div className="row">
          {/* Mapping over the services data to create a card for each item */}
          {services.map(
            (
              { mainIcon, mainTitle, facilityTitle, facilityList, icon },
              index
            ) => (
              <div className="col-lg-4" key={index}>
                <div className="card card-margin">
                  <div className="card-body pt-0">
                    <div className="widget-49">
                      <div
                        className={`widget-49-title-wrapper widget-49-date-${icon}`}
                      >
                        {/* Displaying the main icon and title */}
                        <div className="widget-49-date-primary">
                          <span className="widget-49-date-day">
                            {mainIcon.materialIcons}
                          </span>
                        </div>
                        <div className="widget-49-meeting-info hide-text-on-mobile">
                          <span className=" fs-5 widget-49-pro-title">
                            {mainTitle}
                          </span>
                          <span className="widget-49-meeting-time">
                            {facilityTitle}
                          </span>
                        </div>
                      </div>
                      {/* Displaying the list of facilities */}
                      <div className="widget-49-date-primary pers-width hide-text-on-mobile">
                        {facilityList.map((facility, index) => (
                          <React.Fragment key={index}>
                            <div className="facility-item">
                              <span className="ms-5 widget-49-date-day ">
                                {facility.icon}
                              </span>
                              <span className="ms-3 widget-49-date-month hide-text-on-mobile ">
                                {facility.text}
                              </span>
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                      {/* <div className="widget-49-date-primary">
                        <span className="ms-5 widget-49-date-day">
                          {facilityList.icon}
                        </span>
                        <span className="ms-3 widget-49-date-month">
                          {facilityList.text}
                        </span>
                      </div> */}
                      {/* <div className="  widget-49-meeting-action"></div> */}
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Service;
