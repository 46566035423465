import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import '../css/_aboutUs.scss';
import 'leaflet/dist/leaflet.css';

// Fix for the marker icon path
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const AboutUs = () => {
  const defaultCenter = {
    lat: 51.500737,
    lng: -0.019057,
  };

  // Add your hotel locations as an array of objects with "name", "address", "lat", and "lng" properties
  const hotelLocations = [
    {
      name: 'Wns Hotel ',
      address: 'Skyline Business Village, Skylines Village, London, UK',
      lat: 51.4985172,
      lng: -0.0131714,
    },
    {
      name: 'Wns Hotel ',
      address:
        '1st & 2nd Floor, Wns Hotel Canary Wharf, Canary Wharf, Pepper Street, London, UK',
      lat: 51.4962885,
      lng: -0.0178191,
    },
    {
      name: 'Wns Hotel ',
      address: '59 Limeharbour',
      lat: 51.4984834,
      lng: -0.0153943,
    },
    {
      name: 'Wns Hotel ',
      address: '58 Limeharbour',
      lat: 51.49855784,
      lng: -0.01534,
    },
    {
      name: 'Wns Hotel ',
      address: 'Southend -on-Sea, Eastern Esplanade, Southend-on-Sea, UK',
      lat: 51.5303992,
      lng: 0.7400613,
    },
  ];

  return (
    <Container>
      <Row className="mt-personal">
        <Col md={6}>
          <h2>About Us</h2>
          <p className="pers-para lead text-left">
            Established in 2017, Wonderful Night Sleep Hotel is dedicated to
            providing all our guests a haven of comfort and relaxation. At our
            hotel, we strive to ensure that every guest enjoys a restful and
            rejuvenating stay. Choose from our wide range of premium rooms, each
            meticulously designed to cater to the diverse needs of families,
            solo travellers, groups of friends, and more. Our unwavering
            commitment to exceptional hospitality and guest satisfaction has
            earned us the distinction of being one of the most cherished hotels
            in the UK.
          </p>
        </Col>
        <Col md={6}>
          <MapContainer
            center={[defaultCenter.lat, defaultCenter.lng]}
            zoom={13}
            className="map-one"
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {hotelLocations.map((location, index) => (
              <Marker key={index} position={[location.lat, location.lng]}>
                <Popup>
                  <strong>{location.name}</strong>
                  <br />
                  {location.address}
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </Col>
        <Col md={6} className="about-pers">
          <h2>Contact Us</h2>
          <p className="pers-para">
            For any enquiries, feel free check the information on our website or
            use the below contacts. <br /> +44 0777 0120 123 <br />{' '}
            bookings@wnsh.co.uk
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutUs;
