import React from 'react';
import '../css/_services.scss';

import { services } from './dynamic-data/services';

const CustomComponent = () => {
  return (
    <div className="container mar-pers">
      <div className="row my-4">
        <div className="col-md-6">
          <img
            src={require('../assets/services/img-1.jpeg')}
            className="img-fluid img-pers "
          />
        </div>
        <div className="col-md-6 d-flex align-items-center ">
          <p className="lead text-left">
            Welcome to WNSH Hotel, where timeless elegance meets contemporary
            luxury. As you enter our exquisite reception and waiting area, you
            will be enveloped by a warm and inviting ambience, designed to make
            your stay with us an unforgettable experience. Our tastefully
            decorated reception area showcases a perfect blend of sophistication
            and comfort, featuring stylish furnishings, elegant lighting, and a
            carefully curated collection of artwork. Our attentive and friendly
            staff, dressed in immaculate uniforms, are always on hand to attend
            to your every need, ensuring a seamless check-in process and
            personalised service.
          </p>
        </div>
      </div>
      <div className="row my-4">
        <div className="col-md-6 d-flex align-items-center mt-5">
          <p className="lead text-left">
            The waiting area is a haven of relaxation and tranquillity, offering
            plush seating options, soothing background music, and an array of
            complimentary refreshments. The space is thoughtfully designed to
            cater to both leisure and business travellers, with a dedicated
            reading corner, charging stations for your devices, and
            complimentary high-speed Wi-Fi. As you unwind in the serene
            surroundings, feel free to peruse our collection of local and
            international magazines and newspapers or simply enjoy the
            mesmerising view of our lush gardens or the sparkling city skyline.
            <br />
            <br />
            At WNSH Hotel, we take pride in providing an unparalleled level of
            hospitality and creating a home away from home for our guests. We
            are delighted to have you with us and look forward to making your
            stay truly memorable.
          </p>
        </div>
        <div className="col-md-6">
          <img
            src={require('../assets/services/img-2.jpeg')}
            alt="Image 2"
            className="img-fluid img-pers"
          />
        </div>
      </div>
      <div className="container mar-pers ">
        <div className="row">
          {/* Mapping over the services data to create a card for each item */}
          {services.map(
            ({ mainIcon, mainTitle, facilityTitle, icon }, index) => (
              <div className="col-lg-4" key={index}>
                <div className="card card-margin">
                  <div className="card-body pt-0">
                    <div className="widget-49">
                      <div
                        className={`widget-49-title-wrapper widget-49-date-${icon}`}
                      >
                        {/* Displaying the main icon and title */}
                        <div className="widget-49-date-primary">
                          <span className="widget-49-date-day">
                            {mainIcon.materialIcons}
                          </span>
                        </div>
                        <div className="widget-49-meeting-info hide-text-on-mobile">
                          <span className=" fs-5 widget-49-pro-title">
                            {mainTitle}
                          </span>
                          <span className="widget-49-meeting-time">
                            {facilityTitle}
                          </span>
                        </div>
                      </div>
                      {/* Displaying the list of facilities */}
                      {/* <div className="widget-49-date-primary pers-width hide-text-on-mobile">
                        {facilityList.map((facility, index) => (
                          <React.Fragment key={index}>
                            <div className="facility-item">
                              <span className="ms-5 widget-49-date-day ">
                                {facility.icon}
                              </span>
                              <span className="ms-3 widget-49-date-month hide-text-on-mobile">
                                {facility.text}
                              </span>
                            </div>
                          </React.Fragment>
                        ))}
                      </div> */}
                      {/* <div className="widget-49-date-primary">
                        <span className="ms-5 widget-49-date-day">
                          {facilityList.icon}
                        </span>
                        <span className="ms-3 widget-49-date-month">
                          {facilityList.text}
                        </span>
                      </div> */}
                      {/* <div className="  widget-49-meeting-action"></div> */}
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomComponent;
