import React from 'react';
import '../css/_footer.scss';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Link, useNavigate } from 'react-router-dom';
import locationsData from './dynamic-data/locations.json';

function Footer() {
  const navigate = useNavigate();

  const handleLocationClick = (routepath) => {
    navigate(routepath, { replace: true });
    window.location.reload();
  };

  const renderAddress = (location) => (
    <span
      className="text-decoration-none text-muted cursor-pointer"
      onClick={() => handleLocationClick(location.routePath)}
    >
      {location.name}
    </span>
  );

  return (
    <footer className="w-100 py-4 flex-shrink-0 bg-light pers-styl">
      <div className="container py-4">
        <div className="row gy-4 gx-5">
          <div className="col-lg-4 col-md-6 margin-custom">
            <img
              className="img-logo"
              src={require('../assets/transparent_logo.png')}
              alt="Logo"
            />
          </div>
          <div className="col-lg-2 col-md-6 info-set">
            <h5 className="mb-3">Information</h5>
            <ul className="list-unstyled text-muted">
              <li>
                <Link className="text-decoration-none text-muted" to="/about">
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  className="text-decoration-none text-muted"
                  to="/services"
                >
                  Services
                </Link>
              </li>
              <li>
                <a
                  className="text-decoration-none text-muted"
                  href="https://direct-book.com/properties/wnshoteldirect/"
                >
                  Book Now
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-md-6">
            <h5 className="mb-3">
              <Link className="text-decoration-none">Locations</Link>
            </h5>
            <p className="small text-muted">
              {locationsData.map((location, index) => (
                <React.Fragment key={location.id}>
                  {renderAddress(location)}
                  {index < locationsData.length - 1 && <br />}
                </React.Fragment>
              ))}
            </p>
          </div>

          <div className="col-lg-2 col-md-6">
            <h5 className="mb-3">
              <Link className="text-decoration-none">Social</Link>
            </h5>
            <div className="social-icons">
              <a
                href="https://www.facebook.com/profile.php?id=100069147283423"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon className="icon" />
              </a>
              <a
                href="https://www.instagram.com/wnshotel/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon className="icon" />
              </a>
              <a
                href="https://www.linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon className="icon" />
              </a>
              <a
                href="https://www.twitter.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon className="icon" />
              </a>
            </div>
          </div>
          <p className="small text-muted mb-0 copyright margin-pers">
            &copy; Copyrights. All rights reserved 2023
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
