import InfoIcon from '@mui/icons-material/Info';

import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import CheckIcon from '@mui/icons-material/Check';
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined';
import MiscellaneousServicesOutlinedIcon from '@mui/icons-material/MiscellaneousServicesOutlined';
import TvOutlinedIcon from '@mui/icons-material/TvOutlined';
import LocalDiningIcon from '@mui/icons-material/LocalDining';

export const services = [
  {
    mainIcon: { materialIcons: <InfoIcon /> },
    mainTitle: 'General',
    facilityTitle: 'Tea/coffee maker in all rooms',
    facilityList: [
      {
        icon: <CheckIcon sx={{ fontSize: 15 }} />,
        text: 'Carbon monoxide detector',
      },
      { icon: <CheckIcon sx={{ fontSize: 15 }} />, text: 'Minimarket on site' },
      { icon: <CheckIcon sx={{ fontSize: 15 }} />, text: 'Non-smoking rooms' },
      { icon: <CheckIcon sx={{ fontSize: 15 }} />, text: 'Private parking' },
      { icon: <CheckIcon sx={{ fontSize: 15 }} />, text: 'Ironing facilities' },
    ],
  },
  {
    mainIcon: { materialIcons: <EventAvailableIcon /> },
    mainTitle: 'Bedroom & Bathroom',
    facilityTitle: 'Room service',
    facilityList: [
      {
        icon: <CheckIcon sx={{ fontSize: 15 }} />,
        text: 'Towels/sheets (extra fee)',
      },
      { icon: <CheckIcon sx={{ fontSize: 15 }} />, text: 'Private bathroom' },
      { icon: <CheckIcon sx={{ fontSize: 15 }} />, text: 'Non-smoking rooms' },
      { icon: <CheckIcon sx={{ fontSize: 15 }} />, text: 'Tea/Coffee maker' },
      { icon: <CheckIcon sx={{ fontSize: 15 }} />, text: 'Free toiletries' },
    ],
  },
  {
    mainIcon: { materialIcons: <LockPersonOutlinedIcon /> },
    mainTitle: 'Safety & Security',
    facilityTitle: '24-hour security',
    facilityList: [
      { icon: <CheckIcon sx={{ fontSize: 15 }} />, text: 'Fire extinguishers' },
      {
        icon: <CheckIcon sx={{ fontSize: 15 }} />,
        text: 'CCTV outside property',
      },
      {
        icon: <CheckIcon sx={{ fontSize: 15 }} />,
        text: 'CCTV in common areas',
      },
      { icon: <CheckIcon sx={{ fontSize: 15 }} />, text: '24-hour security' },
      { icon: <CheckIcon sx={{ fontSize: 15 }} />, text: 'Smoke alarms' },
    ],
  },
  {
    mainIcon: { materialIcons: <MiscellaneousServicesOutlinedIcon /> },
    mainTitle: 'Services',
    facilityTitle: 'Express check-in/check-out',
    facilityList: [
      {
        icon: <CheckIcon sx={{ fontSize: 15 }} />,
        text: 'ATM/cash machine on site',
      },
      {
        icon: <CheckIcon sx={{ fontSize: 15 }} />,
        text: 'Fax/photocopying',
      },
      {
        icon: <CheckIcon sx={{ fontSize: 15 }} />,
        text: 'Express check-in/check-out',
      },
      { icon: <CheckIcon sx={{ fontSize: 15 }} />, text: 'Barber/beauty shop' },
      { icon: <CheckIcon sx={{ fontSize: 15 }} />, text: 'Invoice provided' },
    ],
  },
  {
    mainIcon: { materialIcons: <TvOutlinedIcon /> },
    mainTitle: 'Media & Technology',
    facilityTitle: 'Flat-screen TV',
    facilityList: [
      { icon: <CheckIcon sx={{ fontSize: 15 }} />, text: 'Flat-screen TV' },
      { icon: <CheckIcon sx={{ fontSize: 15 }} />, text: 'Garden view' },
      { icon: <CheckIcon sx={{ fontSize: 15 }} />, text: 'Free WiFi' },
      { icon: <CheckIcon sx={{ fontSize: 15 }} />, text: 'Heating' },
      { icon: <CheckIcon sx={{ fontSize: 15 }} />, text: 'Private entrance' },
    ],
  },
  {
    mainIcon: { materialIcons: <LocalDiningIcon /> },
    mainTitle: 'Tea/Coffee maker',
    facilityTitle: 'Electric kettle',
    facilityList: [
      { icon: <CheckIcon sx={{ fontSize: 15 }} />, text: 'Room service' },
      {
        icon: <CheckIcon sx={{ fontSize: 15 }} />,
        text: 'WiFi free of charge ',
      },
      {
        icon: <CheckIcon sx={{ fontSize: 15 }} />,
        text: 'Cleaning products',
      },
      { icon: <CheckIcon sx={{ fontSize: 15 }} />, text: 'Tea/Coffee maker' },
      { icon: <CheckIcon sx={{ fontSize: 15 }} />, text: 'Minimarket on site' },
    ],
  },
];
