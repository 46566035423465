import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/_header.scss';

function Header(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const handleLinkClick = (link) => {
    setIsMenuOpen(true);
    props.history.push(link);
  };

  const handleToggleClick = () => {
    setIsMenuOpen(!isMenuOpen);
    if (!isMenuOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  };

  const handleButtonClick = () => {
    const iframeUrl = 'https://direct-book.com/properties/wnshoteldirect/';
    window.open(iframeUrl, '_blank');
  };

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
        <NavLink
          className="navbar-brand"
          to="/home"
          onClick={() => handleLinkClick('/home')}
        >
          <img
            alt="brand logo"
            src={require('../assets/mini_logo_dark.webp')}
            width="100"
            className="d-inline-block"
          />
        </NavLink>
        <button
          className="navbar-toggler custom-toggler"
          type="button"
          data-toggle="collapse"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={handleToggleClick}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}
          id="navbarNavDropdown"
          ref={menuRef}
        >
          <ul className="navbar-nav justify-content-center">
            <li className="nav-item active">
              <NavLink
                className="nav-link"
                to="/home"
                onClick={handleLinkClick}
              >
                Home <span className="sr-only">(current)</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/rooms"
                onClick={handleLinkClick}
              >
                Room and Suits
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/services"
                onClick={handleLinkClick}
              >
                Services
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/about"
                onClick={handleLinkClick}
              >
                About Us
              </NavLink>
            </li>
          </ul>
          <button
            className="btn btn-sm btn-outline-secondary ml-auto"
            onClick={handleButtonClick}
          >
            Book Now
          </button>
        </div>
      </nav>
    </>
  );
}

export default Header;
