import React from 'react';

function LocationDetails({ location }) {
  // The LocationDetails component expects a location prop

  return (
    // Render a div with the "row mt-5" CSS classes
    <div className="row mt-5">
      {/* Render a div with the "lead text-left" CSS classes */}
      <div className="lead text-left ">
        {/* Render the location title inside an h1 tag with the "display-4 mt-5" CSS classes */}
        <h1 className="display-4 mt-5">{location.title}</h1>
      </div>
    </div>
  );
}

export default LocationDetails;
