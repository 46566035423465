import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

function LocationMap({ location }) {
  // The LocationMap component expects a location prop

  // Define the position as an array with the latitude and longitude of the location
  const position = [location.latitude, location.longitude];

  return (
    // Render a div with the "row mt-4 mb-5" CSS classes
    <div className="row mt-4 mb-5 ">
      {/* Render a div with the "col" CSS class */}
      <div className="col ">
        {/* Render the MapContainer component with the center, zoom, and style properties */}
        <MapContainer
          center={position}
          zoom={13}
          style={{ height: '250px', width: '50%' }}
        >
          {/* Render the TileLayer component to display the map tiles */}
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {/* Render the Marker component with the position property */}
          <Marker position={position}>
            {/* Render the Popup component inside the Marker to display the location name */}
            <Popup>{location.name}</Popup>
          </Marker>
        </MapContainer>
      </div>
    </div>
  );
}

// Export the LocationMap component as a memoized component
export default React.memo(LocationMap);
