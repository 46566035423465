import React from 'react';
import KingBedIcon from '@mui/icons-material/KingBed';
import '../css/_roomSuits.scss';

const rooms = [
  {
    id: 1,
    name: 'Family Room',
    beds: ['KingBed', 'KingBed'],
    image: require('../assets/rooms/twin-room.jpeg'),
    description:
      'This family room features a tea/coffee maker, electric kettle and private entrance. Comfy beds and a Room size of 139 ft².',
  },
  {
    id: 2,
    name: 'Double Room',
    beds: ['KingBed'],
    image: require('../assets/rooms/double-room.jpeg'),
    description:
      'This double room has a flat-screen TV and electric kettle. A Room size of 85 ft².',
  },
  {
    id: 3,
    name: 'Superior King Room',
    beds: ['KingBed'],
    image: require('../assets/rooms/superior-king.jpeg'),
    description:
      'This double room has a private entrance, electric kettle and hot tub. With a room size of 267 ft².',
  },
  {
    id: 4,
    name: 'Standard Twin Room',
    beds: ['KingBed', 'KindBed'],
    image: require('../assets/rooms/standard twin room.jpeg'),
    description:
      'This double room has a private entrance, electric kettle and hot tub. With a room size of 96 ft².',
  },
  {
    id: 5,
    name: 'Deluxe Family Suite',
    beds: ['KingBed'],
    image: require('../assets/rooms/Deluxe Family Suite.jpg'),
    description:
      'This family room has a electric kettle, flat-screen TV and tea/coffee maker and a Room size 165 ft².',
  },
  {
    id: 6,
    name: 'Executive Suite',
    beds: ['KingBed'],
    image: require('../assets/rooms/Executive Suite.jpeg'),
    description:
      'This suite features a view, sofa, microwave, electric kettle and hot tub. With a room size of 267 ft².',
  },
];

function RoomSuits() {
  return (
    <div>
      <>
        <div className="main-container"></div>
        <div className="wrap margin-pers">
          {rooms.map((room) => (
            <div className="box" key={room.id}>
              <div className="box-top">
                <img className="box-image" src={room.image} alt={room.name} />
                <div className="title-flex">
                  <h3 className="box-title">{room.name}</h3>
                  <p className="user-follow-info">
                    {room.beds.map((bed, index) => (
                      <KingBedIcon key={index} className="king-bed" />
                    ))}
                  </p>
                </div>
                <p className="description">{room.description}</p>
              </div>
              <a
                href="https://direct-book.com/properties/wnshoteldirect/"
                className="button custom-a"
                target="_blank"
              >
                Book Now
              </a>
            </div>
          ))}
        </div>
      </>
    </div>
  );
}

export default RoomSuits;
