import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import locationsData from '../../components/dynamic-data/locations.json';
import LocationDetails from './LocationDetails';
import LocationImages from './LocationImages';
import LocationMap from './LocationMap';

function Locations() {
  // Get the locationPath from the URL params
  const { locationPath } = useParams();

  // Initialize the location state and the setLocation function to update it
  const [location, setLocation] = useState(null);

  // Get the navigate function from the react-router-dom to programmatically navigate
  const navigate = useNavigate();

  // Run an effect when the locationPath changes
  useEffect(() => {
    // Find the location object that matches the current locationPath
    const foundLocation = locationsData.find(
      (location) => location.routePath === '/locations/' + locationPath
    );

    // If the location is not found, navigate to the homepage
    if (!foundLocation) {
      navigate('/');
    } else {
      // If the location is found, update the location state
      setLocation(foundLocation);
    }
  }, [locationPath, navigate]);

  // If the location is not set, return null, meaning nothing will be rendered
  if (!location) {
    return null;
  }

  // Render the LocationDetails, LocationImages, and LocationMap components
  return (
    <div className="container">
      <LocationDetails location={location} />
      <LocationImages location={location} />
      <LocationMap location={location} />
    </div>
  );
}

export default Locations;
